/*font stylesheets*/
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");/*used to create icons - first for IE8*/


@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,300,300italic,400italic,700,700italic);/*main font used throughout website*/

body {
  font-family: 'Roboto Sans', sans-serif;
}

$theme-colors: (
  "primary": #004F50,
  "secondary":  #8E1F60,
);

.bg-csonav {
    background: #046F77;
}

.border-none {
  border: none !important;
}


// Start - Amplify customisations

amplify-authenticator {
  --amplify-primary-color: #006168;
  --amplify-font-family: 'Roboto Sans', sans-serif;
  --amplify-secondary-tint: #DC4128;
}

// End - Amplify customisations