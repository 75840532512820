// .logoFullWidth {
//     width: 100%;
// }

//@media only screen and (max-width : 480px) {
   .logoFullWidth {
        width: 100%;
    }

    .elementToStickToBottom {
        position: fixed;
        bottom: 0;
        left: 0;
        padding-top: 20px;
        height:60vh;
      }
      
      .containerElement {
        position: relative;
        margin-right: auto;
        margin-left: auto;
        left: 0;
        bottom: 0;
        //margin:0;
        width:100%;
        height:25vh;
      }

      .footerTitle{
        color: #006168;
        padding-left:20px;
        padding-top: 15px;
        font-size:1.5rem;
        font-weight:bolder
    }

    .footText{
        font-weight:bold;
        color:#006168;
        padding-left:20px;
        padding-top:15px;
        margin-bottom:0;
    }

    .footContact {
        margin: 0;
        font-family: "Roboto Sans", sans-serif;
        font-size: 1rem;
        color:#006168;
        padding-left:20px;
        font-weight: 400;
        line-height: 1.5;
        text-align: left;
    }
//}