.messageToast {
    color: white;
}

.infoMessageToastHeader {
    @extend .messageToast;
    background: #35456B;
}

.infoMessageToastBody {
    @extend .messageToast;
    background: #A4A6BB;
}

.errorMessageToastHeader {
    @extend .messageToast;
    background: #DC4128;
}

.errorMessageToastBody {
    @extend .messageToast;
    background: #EF876A;
}

.successMessageToastHeader {
    @extend .messageToast;
    background: #908C34;
}

.successMessageToastBody {
    @extend .messageToast;
    background: #D7D574;
}

.warningMessageToastHeader {
    @extend .messageToast;
    background: #FAA21B;
}

.warningMessageToastBody {
    @extend .messageToast;
    background: #FDBF73;
}