.messageToast {
    color: white;
}

.infoAlertHeader {
    @extend .messageToast;
    background: #35456B;
}

.infoAlertBody {
    @extend .messageToast;
    background: #A4A6BB;
}

.errorAlertHeader {
    @extend .messageToast;
    background: #DC4128;
}

.errorAlertBody {
    @extend .messageToast;
    background: #EF876A;
}

.successAlertHeader {
    @extend .messageToast;
    background: #908C34;
}

.successAlertBody {
    @extend .messageToast;
    background: #D7D574;
}

.warningAlertHeader {
    @extend .messageToast;
    background: #FAA21B;
}

.warningAlertBody {
    @extend .messageToast;
    background: #FDBF73;
}