// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
.navbar-expand {
    flex-wrap: wrap !important;
}

.hydrated {
    margin: auto;
}

.sign-up-form-footer > :first-child{
    display: flex;
}
@media all and (max-width: 900px) {
    .user-action{
		margin-top: 3rem;
	}
	.allocate-user{
        flex-direction: column;
    }
}